import React from "react"
import Header from "../components/header/Header"
import { Link } from "gatsby"
import Navbar from "../components/navbar/navbar"

const Home = () => {
  return (
    <div>
      <Header />
      <Navbar />
      <div>
        <h1 style={{ textAlign: "center", color: "#90a7e0" }}>404</h1>
        <p
          style={{
            textAlign: "center",
            color: "#90a7e0",
            textDecoration: "none",
          }}
        >
          Whoops, something has gone wrong, please return to the home page{" "}
          <Link to="/home">here</Link>
        </p>
      </div>
    </div>
  )
}

export default Home
